import React, { useState, useEffect } from "react";
// import GLightbox from "glightbox";
import PureCounter from "@srexi/purecounterjs";
import Isotope, { data } from "isotope-layout";
import AOS from "aos";
import "aos/dist/aos.css";

import "bootstrap";
import "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";

import Navbar from "./components/navbar";
import HeroSection from "./components/hero";
import AboutSection from "./components/aboutSection";
import StatsSection from "./components/statSection";
import ServicesSection from "./components/servicesSection";
import FAQ from "./components/faq";
import Ministers from "./components/ministers";
import CTA from "./components/cta";
import Testimonials from "./components/testimonials";
import Contact from "./components/contact";
import Footer from "./components/footer";
import ScrollToTop from "./components/scrollToTop";
import Preloader from "./components/preloader";
import SchedulesSection from "./components/schedulesSection";

// Import data
import jsonData from "./data/data.json";

function App() {
  const [Data, setData] = useState(jsonData.en);
  const stats = jsonData.stats;

  const [lang, setLang] = useState("en");

  useEffect(() => {
    if (lang === "en") {
      setData(jsonData.en);
    } else if (lang === "oro") {
      setData(jsonData.oro);
    } else if (lang === "amh") {
      setData(jsonData.amh);
    }
  }, [lang]);

  useEffect(() => {
    if (!localStorage.language) {
      setLang("en");
      localStorage.language = lang;
    } else {
      setLang(localStorage.language);
    }

    const preloader = document.querySelector("#preloader");

    const timeoutId = setTimeout(() => {
      if (preloader) {
        preloader.remove();
      }

      // Call other functions that depend on the load event here
      toggleScrollTop();
      toggleScrolled();
      initIsotopeLayout();
      aosInit();
    }, 2000);

    const toggleScrollTop = () => {
      const scrollTop = document.querySelector(".scroll-top");
      if (scrollTop) {
        scrollTop.classList.toggle("active", window.scrollY > 100);
      }
    };

    const toggleScrolled = () => {
      const selectBody = document.querySelector("body");
      const selectHeader = document.querySelector("#header");
      if (
        !selectHeader.classList.contains("scroll-up-sticky") &&
        !selectHeader.classList.contains("sticky-top") &&
        !selectHeader.classList.contains("fixed-top")
      )
        return;
      selectBody.classList.toggle("scrolled", window.scrollY > 100);
    };

    // Scroll Up Sticky Header
    let lastScrollTop = 0;
    const handleScroll = () => {
      const selectHeader = document.querySelector("#header");
      if (!selectHeader.classList.contains("scroll-up-sticky")) return;

      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop && scrollTop > selectHeader.offsetHeight) {
        selectHeader.style.setProperty("position", "sticky", "important");
        selectHeader.style.top = `-${selectHeader.offsetHeight + 50}px`;
      } else if (scrollTop > selectHeader.offsetHeight) {
        selectHeader.style.setProperty("position", "sticky", "important");
        selectHeader.style.top = "0";
      } else {
        selectHeader.style.removeProperty("top");
        selectHeader.style.removeProperty("position");
      }
      lastScrollTop = scrollTop;
    };

    // Correct scrolling position for hash links
    const handleHashLinks = () => {
      if (window.location.hash) {
        const section = document.querySelector(window.location.hash);
        if (section) {
          setTimeout(() => {
            const scrollMarginTop = getComputedStyle(section).scrollMarginTop;
            window.scrollTo({
              top: section.offsetTop - parseInt(scrollMarginTop),
              behavior: "smooth",
            });
          }, 100);
        }
      }
    };

    // Initiate PureCounter
    new PureCounter();

    // Init Isotope Layout
    const initIsotopeLayout = () => {
      document
        .querySelectorAll(".isotope-layout")
        .forEach(function (isotopeItem) {
          const layout = isotopeItem.getAttribute("data-layout") ?? "masonry";
          const filter = isotopeItem.getAttribute("data-default-filter") ?? "*";
          const sort =
            isotopeItem.getAttribute("data-sort") ?? "original-order";

          const initIsotope = new Isotope(
            isotopeItem.querySelector(".isotope-container"),
            {
              itemSelector: ".isotope-item",
              layoutMode: layout,
              filter: filter,
              sortBy: sort,
            }
          );

          isotopeItem
            .querySelectorAll(".isotope-filters li")
            .forEach(function (filters) {
              filters.addEventListener("click", function () {
                isotopeItem
                  .querySelector(".isotope-filters .filter-active")
                  .classList.remove("filter-active");
                this.classList.add("filter-active");
                initIsotope.arrange({
                  filter: this.getAttribute("data-filter"),
                });
                if (typeof aosInit === "function") {
                  aosInit();
                }
              });
            });
        });
    };

    const aosInit = () => {
      AOS.init({
        duration: 600,
        easing: "ease-in-out",
        once: true,
        mirror: false,
      });
    };

    // Add event listeners
    document.addEventListener("scroll", toggleScrollTop);
    document.addEventListener("scroll", toggleScrolled);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("load", handleHashLinks);

    // Cleanup listeners on unmount
    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener("scroll", toggleScrollTop);
      document.removeEventListener("scroll", toggleScrolled);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("load", handleHashLinks);
    };
  }, []);

  return (
    <div className="App">
      <Navbar data={Data.navbar} lang={lang} setLang={setLang} />

      <main id="main">
        <HeroSection data={Data.home} />

        <AboutSection data={Data.about} />

        <StatsSection stat={stats} data={Data.stats} />

        <ServicesSection data={Data.services} />

        <SchedulesSection data={Data.schedules} />

        <Ministers data={Data.ministers} />

        <CTA data={Data.cta} />

        <FAQ data={Data.faq} />

        <Testimonials data={Data.testimonials} />

        <Contact data={Data.contact} form={Data.contactForm} />
      </main>
      <Footer links={Data.navbar} data={Data} services={Data.services} />

      <ScrollToTop />

      <Preloader />
    </div>
  );
}

export default App;
