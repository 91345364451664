import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

function Contact({ data, form }) {
  const contactForm = useRef();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const sendEmail = (e) => {
    setLoading(true);
    setSuccess(false);
    setError(false);

    e.preventDefault();

    emailjs
      .sendForm("service_sa4kggr", "template_h1qoftp", contactForm.current, {
        publicKey: "4zEwgNmtedzRKahud",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setSuccess(true);
          setError(false);
          setLoading(false);
          contactForm.current.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
          setError(true);
          setSuccess(false);
          setLoading(false);
        }
      );
  };

  return (
    <section id="contact" className="contact">
      <div className="container section-title" data-aos="fade-up">
        <h2>{data.title}</h2>
        <p>{data.subtext}</p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-6">
            <div className="row gy-4">
              <div className="col-md-6">
                <div className="info-item" data-aos="fade" data-aos-delay="200">
                  <i className="bi bi-geo-alt"></i>
                  <h3>{data.addressTitle}</h3>
                  {data.addressValue.split("\n").map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </div>
              </div>

              <div className="col-md-6">
                <div className="info-item" data-aos="fade" data-aos-delay="300">
                  <i className="bi bi-telephone"></i>
                  <h3>{data.callTitle}</h3>
                  <p>{data.phone1}</p>
                  <p>{data.phone2}</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="info-item" data-aos="fade" data-aos-delay="400">
                  <i className="bi bi-envelope"></i>
                  <h3>{data.emailTitle}</h3>
                  <p>{data.email1}</p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="info-item" data-aos="fade" data-aos-delay="500">
                  <i className="bi bi-clock"></i>
                  <h3>{data.openHoursTitle}</h3>
                  <p>{data.openHours1}</p>
                  <p>{data.openHours2}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <form
              ref={contactForm}
              onSubmit={sendEmail}
              className="contact-email-form"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="row gy-4">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder={form.nameTitle}
                    required
                  />
                </div>

                <div className="col-md-6">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder={form.emailTitle}
                    required
                  />
                </div>

                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    placeholder={form.subjectTitle}
                    required
                  />
                </div>

                <div className="col-md-12">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="6"
                    placeholder={form.messageTitle}
                    required
                  ></textarea>
                </div>

                <div className="col-md-12 text-center">
                  {loading && (
                    <div className="loading">{form.loadingMessage}</div>
                  )}
                  {error && (
                    <div className="error-message">{form.errorMessage}</div>
                  )}
                  {success && (
                    <div className="sent-message">{form.successMessage}</div>
                  )}
                  {/* {loading ? (
                    <button className="form-btn" type="submit">
                      {form.loadingMessage}
                    </button>
                  ) : ( */}
                  <button className="form-btn" type="submit">
                    {form.submitButton}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
