import React from "react";

function Footer({ data }) {
  return (
    <footer id="footer" className="footer">
      <div className="container footer-top">
        <div className="row gy-4">
          <div className="col-lg-5 col-md-12 footer-about">
            <a href="index.html" className="logo d-flex align-items-center">
              <span>BKC</span>
            </a>
            <p>{data.footer.text}</p>
            {/* <div className="social-links d-flex mt-4">
              <a href="#">
                <i className="bi bi-twitter"></i>
              </a>
              <a href="#">
                <i className="bi bi-facebook"></i>
              </a>
              <a href="#">
                <i className="bi bi-instagram"></i>
              </a>
              <a href="#">
                <i className="bi bi-linkedin"></i>
              </a>
            </div> */}
          </div>

          <div className="col-lg-2 col-6 footer-links">
            <h4>{data.footer.usefulLinks.title}</h4>
            <ul>
              {Object.entries(data.navbar).map(([key, value], index) => (
                <li key={index}>
                  <a href={`#${key}`}>{value}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-lg-2 col-6 footer-links">
            <h4>{data.services.title}</h4>
            <ul>
              {data.services.content.map((service, index) => (
                <li key={index}>
                  <a href="">{service.serviceTitle}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
            <h4>{data.footer.contact.title}</h4>
            <p>{data.footer.contact.subcity}</p>
            <p>{data.footer.contact.city}</p>
            <p>{data.footer.contact.country}</p>
            <p className="mt-4">
              <strong>Phone:</strong> <span>{data.footer.contact.phone}</span>
            </p>
            <p>
              <strong>Email:</strong> <span>{data.footer.contact.email}</span>
            </p>
          </div>
        </div>
      </div>

      <div className="copyright text-center mt-4">
        <p>
          &copy; <span>Copyright</span> <strong className="px-1">BKC</strong>{" "}
          <span>All Rights Reserved</span>
        </p>
        <div className="credits">
          Designed by <a href="https://sentack-dev.vercel.app/">SENTACK</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
