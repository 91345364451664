import React from "react";

function Ministers({ data }) {
  return (
    <section id="ministers" className="ministers">
      <div className="container section-title" data-aos="fade-up">
        <h2>{data.title}</h2>
        <p>{data.subtext}</p>
      </div>

      <div className="container">
        <div className="row gy-5">
          {data.content.map((member, index) => (
            <div
              key={index}
              className="col-lg-3 col-md-6 member"
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              <div className="member-img">
                <img src={member.imgurl} className="img-fluid" alt="" />
                {/* <div className="social">
                  <a href="#">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div> */}
              </div>
              <div className="member-info text-center">
                <h4>{member.fullName}</h4>
                <span>{member.title}</span>
                <p>{member.verse}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Ministers;
