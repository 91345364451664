import React, { useEffect } from "react";

function StatsSection({ stat, data }) {
  return (
    <section id="stats" class="stats">
      <img src={data.background} alt="" data-aos="fade-in" />

      <div
        class="container position-relative"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div class="row gy-4">
          <div class="col-lg-3 col-md-6">
            <div class="stats-item text-center w-100 h-100">
              <span
                data-purecounter-start="0"
                data-purecounter-end={stat.years}
                data-purecounter-duration="1"
                class="purecounter"
              ></span>
              <p>{data.years}</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="stats-item text-center w-100 h-100">
              <span
                data-purecounter-start="0"
                data-purecounter-end={stat.members}
                data-purecounter-duration="1"
                class="purecounter"
              ></span>
              <p>{data.members}</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="stats-item text-center w-100 h-100">
              <span
                data-purecounter-start="0"
                data-purecounter-end={stat.workers}
                data-purecounter-duration="1"
                class="purecounter"
              ></span>
              <p>{data.workers}</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="stats-item text-center w-100 h-100">
              <span
                data-purecounter-start="0"
                data-purecounter-end={stat.days}
                data-purecounter-duration="1"
                class="purecounter"
              ></span>
              <p>{data.days}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StatsSection;
