import React from "react";

function HeroSection({ data }) {
  return (
    <section id="hero" class="hero">
      <img src="assets/img/worship2.jpg" alt="" data-aos="fade-in" />

      <div class="container">
        <div class="row">
          <div class="col-lg-10">
            <h2 data-aos="fade-up" data-aos-delay="100">
              {data.welcomeText}
            </h2>
            <p data-aos="fade-up" data-aos-delay="200">
              {data.subtitle}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
