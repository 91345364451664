import React from "react";

function ServicesSection({ data }) {
  return (
    <section id="services" className="services">
      <div className="container section-title" data-aos="fade-up">
        <h2>{data.title}</h2>
        <p>{data.subtext}</p>
      </div>

      <div className="container">
        <div className="row gy-4">
          {data.content.map((service, index) => (
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-delay={index * 100}
              key={service.serviceTitle}
            >
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className={service.icon}></i>
                </div>
                <div>
                  <h4 className="title">
                    <a href="" className="stretched-link">
                      {service.serviceTitle}
                    </a>
                  </h4>
                  <p className="description">{service.serviceContent}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ServicesSection;
