import React, { useState } from "react";

function FAQ({ data }) {
  const [active, setActive] = useState(0);

  return (
    <section id="faq" className="faq">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div className="content px-xl-5">
              {localStorage.language === "oro" ? (
                <h3>
                  <strong>{data.title.split(" ")[0]} </strong>
                  <span>{data.title.split(" ")[1]} </span>
                </h3>
              ) : (
                <h3>
                  <span>{data.title.split(" ")[0]} </span>
                  <span>{data.title.split(" ")[1]} </span>
                  <strong>{data.title.split(" ")[2]}</strong>
                </h3>
              )}
              <p>{data.subtext}</p>
            </div>
          </div>
          <div className="col-lg-8" data-aos="fade-up" data-aos-delay="200">
            <div className="faq-container">
              {data.content.map((item, index) => (
                <div
                  onClick={() => setActive(index)}
                  className={`faq-item ${index === active && "faq-active"}`}
                  key={index}
                  active
                >
                  <h3>
                    <span className="num">{index + 1}.</span>{" "}
                    <span>{item.question}</span>
                  </h3>
                  <div className="faq-content">
                    <p>{item.answer}</p>
                  </div>
                  <i className="faq-toggle bi bi-chevron-right"></i>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
