import React from "react";

function CTA({ data }) {
  return (
    <section id="call-to-action" className="call-to-action">
      <img src="assets/img/worship.jpg" alt="" />
      <div className="container">
        <div
          className="row justify-content-center"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="col-xl-10">
            <div className="text-center">
              <h3>{data.title}</h3>
              <p>{data.subtext}</p>
              <a className="cta-btn" href="#">
                {data.button}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CTA;
