import React from "react";

function AboutSection({ data }) {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row align-items-xl-center gy-5">
          <div className="col-xl-5 content">
            <h1>{data.title}</h1>
            <h2>{data.welcomeText}</h2>
            <p>{data.paragraph}</p>
            {/* <a href="#" className="read-more">
              <span>Read More</span>
              <i className="bi bi-arrow-right"></i>
            </a> */}
          </div>
          <div className="col-xl-7">
            <div className="row gy-4 icon-boxes">
              {data.sections.map((section, index) => (
                <div
                  className="col-md-6"
                  key={index}
                  data-aos="fade-up"
                  data-aos-delay={200 + index * 100}
                >
                  <div className="icon-box">
                    <i className={section.icon}></i>
                    <h3>{section.title}</h3>
                    <p>{section.subtext}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
