import React, { useEffect } from "react";

function ScrollToTop() {
  return (
    <a
      href="#"
      id="scroll-top"
      class="scroll-top d-flex align-items-center justify-content-center"
    >
      <i class="bi bi-arrow-up-short"></i>
    </a>
  );
}

export default ScrollToTop;
