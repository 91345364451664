import React from "react";

function SchedulesSection({ data }) {
  return (
    <section id="schedules" className="schedules">
      <div className="container section-title" data-aos="fade-up">
        <h2>{data.title}</h2>
        <p>{data.subtext}</p>
      </div>

      <div className="container">
        {data.content.map((schedule, index) => {
          const isEven = index % 2 === 0;
          return (
            <div
              key={index}
              className={`row align-items-center schedules-item ${
                isEven ? "" : "justify-content-between"
              }`}
            >
              {isEven ? (
                <>
                  <div
                    className="col-lg-5 order-2 order-lg-1"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <h3>{schedule.title}</h3>
                    <ul>
                      <li>
                        <i class="bi bi-clock"></i>{" "}
                        <span>{schedule.subtext}</span>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="col-lg-7 order-1 order-lg-2 d-flex align-items-center  schedules-img-bg"
                    data-aos="zoom-out"
                    data-aos-delay="100"
                  >
                    <img src={schedule.imgurl} alt="" className="stack-front" />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="col-lg-6 d-flex align-items-center schedules-img-bg"
                    data-aos="zoom-out"
                  >
                    <img src={schedule.imgurl} className="img-fluid" alt="" />
                  </div>
                  <div
                    className="col-lg-5 d-flex justify-content-center flex-column"
                    data-aos="fade-up"
                  >
                    <h3>{schedule.title}</h3>
                    <ul>
                      <li>
                        <i class="bi bi-clock"></i>{" "}
                        <span>{schedule.subtext}</span>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default SchedulesSection;
