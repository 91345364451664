import React, { useState } from "react";

function Navbar({ data, lang, setLang }) {
  const [mobileNav, setMobileNav] = useState(false);

  const toggleMobileNav = () => {
    setMobileNav(!mobileNav);
    document.querySelector("body").classList.toggle("mobile-nav-active");
  };

  const toggleLanguage = (lan) => {
    setLang(lan);
    localStorage.language = lan;
  };

  return (
    <header id="header" class="header fixed-top d-flex align-items-center">
      <div class="container-fluid d-flex align-items-center justify-content-between">
        <a href="" class="logo d-flex align-items-center me-auto me-xl-0">
          <img src="assets/img/logo.png" alt="BKC" />
          <h1>BKC</h1>
          <span>.</span>
        </a>

        <nav
          id="navmenu"
          class={`navmenu ${mobileNav ? "mobile-nav-active" : ""}`}
        >
          <ul>
            <li>
              <a href="#hero" class="active">
                {data.hero}
              </a>
            </li>
            <li>
              <a href="#about">{data.about}</a>
            </li>
            <li>
              <a href="#services">{data.services}</a>
            </li>
            <li>
              <a href="#schedules">{data.schedules}</a>
            </li>
            <li>
              <a href="#ministers">{data.ministers}</a>
            </li>
            <li>
              <a href="#contact">{data.contact}</a>
            </li>
          </ul>

          <i
            onClick={toggleMobileNav}
            class={`mobile-nav-toggle d-xl-none bi ${
              mobileNav ? "bi-x" : "bi-list"
            }`}
          ></i>
        </nav>

        <select
          class="btn-getstarted"
          value={lang}
          onChange={(e) => toggleLanguage(e.target.value)}
        >
          <option value="oro">Afan Oromo</option>
          <option value="amh">Amharic</option>
          <option value="en">English</option>
        </select>

        {/* <button onClick={() => toggleLanguage("amh")} class="btn-getstarted">
          {lang === "en" ? "ORO" : "ENG"}
        </button> */}
      </div>
    </header>
  );
}

export default Navbar;
